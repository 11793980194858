//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VentaServices from "../../../Services/VentaServices";

const ventaServices = new VentaServices();
export default {
  name: "buscarVentasModal",
  props: ["model"],
  data() {
    return {
      filtros: {
        busqueda: "",
        status:"200",
        entregaProductos:"100,400",
        ordenamiento: 1,
      },
      ventas: [],
      tipoOpciones: [
        {label: "Contado", value: 100},
        {label: "Crédito", value: 101},
      ],
      ordenOpciones: [
        {label: "Folio Ascendente", value: 1},
        {label: "Folio Descendente", value: 2},
        {label: "Cliente A-Z", value: 3},
        {label: "Cliente Z-A", value: 4},
        {label: "Fecha ascendente", value: 5},
        {label: "Fecha descendente", value: 6},
      ],
      columnas: [
        {label: 'folio', align: 'left', field: 'folio', headerClasses: 'w15p'},
        {
          label: 'cliente',
          align: 'left',
          field: 'nombreComercial',
          headerClasses: 'w50p'
        },{
          label: 'Salida Lotes Series',
          align: 'center',
          field: 'entregaProductos',
          headerClasses: 'w20p',
          format: val => this.obtenerLotesSeries(val)
        },
        {label: 'status', align: 'left', name: 'statusPago', headerClasses: 'w15p'},
      ],
      ventaSeleccionada: [],
      ventasModal: false,
    }
  },
  watch: {
    model(value) {
      if (value) {
        this.limpiarFiltros();
        this.buscarVentas();
      } else
        this.onClose();
    }
  },
  methods: {
    onClose() {
      this.ventasModal = false;
      this.$emit('on-close');
    },
    onShow() {
      this.loader(true);
      this.limpiarFiltros();
      this.loader(false);
    },
    limpiarFiltros() {
      this.filtros = {
        busqueda: "",
        status:"200",
        entregaProductos:"100,400",
        ordenamiento: 1
      };
    },
    buscarVentas(loader = true) {
      if (loader) this.loader(true);
      return ventaServices.listarVentas(this.filtros)
        .then(ventas => {
          if (ventas.length === 1)
            this.obtenerVenta(ventas[0]);
          else {
            this.ventas = ventas;
            this.ventasModal = true;
          }
        }).catch(error => {
          this.alertShow(error, "error");
          this.onClose();
        })
        .then(() => {if (loader) this.loader(false)});
    },
    seleccionarVentaRow(evt, row, index) {
      this.obtenerVenta(row);
    },
    seleccionarVenta(details) {
      this.obtenerVenta(details.rows[0]);
    },
    obtenerVenta(row) {
      this.ventasModal = false;
      this.$emit("get-venta", row.ventaId);
    },
    obtenerLotesSeries(entregaProductos) {
      switch (Number(entregaProductos)) {
        case 100: return "Pendiente";
        case 200: return "Entregados";
        case 300:default: return "No aplica";
        case 400: return "En proceso";
      }
    }
  }
}
